import { useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import InputText, { InputType } from 'bloko/blocks/inputText';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import useToggleState from 'lux/hooks/useToggleState';
import { setEmployerButton } from 'lux/models/employerButtons';
import { isServerError } from 'lux/modules/fetcher';
import { CHECK_MAIL_REGEXP } from 'lux/utils/checkMailRegExp';
import ErrorComponent from 'src/components/EmployerConstructor/ErrorComponent';
import autosearchSave, { STATUS } from 'src/components/EmployerConstructor/autosearchSave';
import Form from 'src/components/Form';
import { employerSidebarAutosearchSaved } from 'src/components/Notifications/EmployerSidebar';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';

import styles from './save-search.less';

const TrlKeys = {
    error: 'vacancySearchResults.saveSearch.error.title',
    wrongEmail: 'vacancySearchResults.saveSearch.wrongEmail.text',
    placeholder: 'vacancySearchResults.saveSearch.withEmail.placeholder',
    saveButton: 'vacancySearchResults.saveSearch.withEmail.saveButton',
    text: 'vacancySearchResults.saveSearch.withEmail.text',
};

interface SaveSearchProps {
    autosearchName: string;
    employerId: number;
    toggleShouldLogin: () => void;
    toggleShowModal: () => void;
}

const SaveSearch: TranslatedComponent<SaveSearchProps> = ({
    autosearchName,
    employerId,
    toggleShouldLogin,
    toggleShowModal,
    trls,
}) => {
    const dispatch = useDispatch();
    const [saveSearchSubmitDisabled, toggleSaveSearchSubmitDisabled] = useToggleState(false);
    const [errorTrl, setErrorTrl] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);
    const { addNotification } = useNotification();

    const saveSearch = useCallback(async () => {
        const email = inputRef.current?.value || '';
        toggleSaveSearchSubmitDisabled();

        if (!CHECK_MAIL_REGEXP.test(email.trim())) {
            setErrorTrl(trls[TrlKeys.wrongEmail]);
            toggleSaveSearchSubmitDisabled();
            return;
        }

        let response;
        try {
            response = await autosearchSave({ email: email.trim(), employerId, autosearchName });
        } catch (error) {
            if (isServerError<{ message?: string }>(error) && error.message === STATUS.CONFLICT) {
                toggleShouldLogin();
                return;
            }
        }

        if (response?.state === STATUS.SUCCESS) {
            toggleShowModal();
            addNotification(employerSidebarAutosearchSaved);
            dispatch(setEmployerButton({ saveSearch: null }));
            return;
        }

        setErrorTrl(trls[TrlKeys.error]);
    }, [
        toggleSaveSearchSubmitDisabled,
        trls,
        dispatch,
        employerId,
        autosearchName,
        toggleShouldLogin,
        toggleShowModal,
        addNotification,
    ]);

    return (
        <div data-qa="vacancy-upgrade-menu">
            <Text>{trls[TrlKeys.text]}</Text>
            <VSpacing base={6} />

            <ErrorComponent show={!!errorTrl}>{errorTrl}</ErrorComponent>
            <Form
                onSubmit={(event) => {
                    event.preventDefault();
                    saveSearch().catch(console.error);
                }}
            >
                <div className={styles.form}>
                    <div className={styles.email}>
                        <InputText
                            ref={inputRef}
                            type={InputType.Email}
                            placeholder={trls[TrlKeys.placeholder]}
                            name="email"
                            autoFocus
                        />
                    </div>
                    <Button
                        kind={ButtonKind.Primary}
                        type={ButtonType.Submit}
                        disabled={saveSearchSubmitDisabled}
                        data-qa="autosearch-subscribe__submit"
                    >
                        {trls[TrlKeys.saveButton]}
                    </Button>
                </div>
                <VSpacing base={4} />
            </Form>
        </div>
    );
};

export default translation(SaveSearch);
