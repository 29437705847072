import PropTypes from 'prop-types';

import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

import HeaderBase from 'src/pages/EmployerConstructor/components/HeaderBase';
import HeaderEdit from 'src/pages/EmployerConstructor/components/HeaderEdit';

const Header = ({ editMode }) => {
    const imagePath = useSelector(
        ({ employerConstructor }) =>
            employerConstructor.images.find((image) => image.pictureId === employerConstructor.pictureId)?.path
    );
    const previewModeWithBranding = useSelector(
        ({ employerConstructor }) => employerConstructor.previewModeWithBranding
    );
    const isZP = useIsZarplataPlatform();

    if (isZP || (!editMode && (!imagePath || !previewModeWithBranding))) {
        return null;
    }
    if (editMode) {
        return <HeaderEdit />;
    }

    return <HeaderBase />;
};

Header.propTypes = {
    editMode: PropTypes.bool,
};

export default Header;
