import { FC, PropsWithChildren } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import PageTabsProvider from 'src/components/Employer/PageTabs/PageTabsProvider';
import SubscribeProvider from 'src/components/Employer/Subscribe/SubscribeProvider';
import WantSame from 'src/components/VacancyView/WantSame';
// На этой странице нужны динамические импорты с целью уменьшения чанка
// eslint-disable-next-line @hh.ru/import-rules/no-internal-modules
import EmployerConstructor from 'src/pages/EmployerConstructor/components/EmployerConstructorContent';
// eslint-disable-next-line @hh.ru/import-rules/no-internal-modules
import EmployerSimple from 'src/pages/EmployerSimpleEdit/components/EmployerSimpleEditContent';

import EmployerChameleon from 'src/pages/EmployerView/components/EmployerChameleon';

const ProvidersWrapper: FC<PropsWithChildren> = ({ children }) => (
    <SubscribeProvider>
        <PageTabsProvider>{children}</PageTabsProvider>
    </SubscribeProvider>
);

const EmployerView: FC = () => {
    const hasConstructor = useSelector((state) => state.employerConstructor.hasConstructor);
    const hasChameleon = useSelector((state) => state.hasChameleon);
    const employerId = useSelector((state) => state.employerInfo?.id);

    if (hasChameleon) {
        return (
            <ProvidersWrapper>
                <EmployerChameleon />
            </ProvidersWrapper>
        );
    }

    return (
        <ProvidersWrapper>
            {hasConstructor ? (
                <>
                    <EmployerConstructor />
                    <WantSame keyPrefix="employerId" id={String(employerId)} />
                </>
            ) : (
                <EmployerSimple />
            )}
        </ProvidersWrapper>
    );
};

export default EmployerView;
