import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { DisplayType } from 'lux/models/displayType';
import ChatBotMessengers from 'src/components/ChatBot/Messengers';
import ChatBotQrCode from 'src/components/ChatBot/QrCode';
import translation from 'src/components/translation';

import styles from './chat-bot.less';

const TrlKeys = {
    messengers: {
        default: 'vacancySearchResults.saveSearch.withChatBot.text.messengers.default',
        desktop: 'vacancySearchResults.saveSearch.withChatBot.text.messengers.desktop',
    },
    qrCode: 'vacancySearchResults.saveSearch.withChatBot.text.qrCode',
};

const SubscribeModalChatBot: TranslatedComponent = ({ trls }) => {
    const displayType = useSelectorNonNullable((store) => store.displayType);
    const isXs = useBreakpoint(Breakpoint.M) === Breakpoint.XS;

    return (
        <div className={styles.content}>
            <div className={styles.contentItem}>
                <Text>
                    {
                        trls[
                            displayType === DisplayType.Desktop
                                ? TrlKeys.messengers.desktop
                                : TrlKeys.messengers.default
                        ]
                    }
                </Text>
                <VSpacing base={3} />
                <ChatBotMessengers column={!isXs} smallButtons={isXs} />
            </div>
            {!isXs && (
                <div className={styles.contentItem}>
                    <Text>{trls[TrlKeys.qrCode]}</Text>
                    <VSpacing base={3} />
                    <ChatBotQrCode />
                </div>
            )}
        </div>
    );
};

export default translation(SubscribeModalChatBot);
