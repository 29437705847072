import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button, { ButtonAppearance } from 'bloko/blocks/button';
import FormSpacer from 'bloko/blocks/form/FormSpacer';
import { H4 } from 'bloko/blocks/header';
import Radio from 'bloko/blocks/radio';
import VSpacing from 'bloko/blocks/vSpacing';

import { setPreviewModeWithBranding } from 'lux/models/employerConstructor';
import { useSelector } from 'lux/modules/useSelector';
import translation from 'src/components/translation';

const ControlContentPreviewMode = ({ toggleEditMode, trls }) => {
    const dispatch = useDispatch();
    const previewModeWithBranding = useSelector(
        ({ employerConstructor }) => employerConstructor.previewModeWithBranding
    );
    const onChange = (e) => dispatch(setPreviewModeWithBranding(e.target.value === '1'));

    return (
        <div className="employer-constructor-controls__content">
            <div className="employer-constructor-controls__content-left">
                <H4>{trls[ControlContentPreviewMode.trls.previewEmployerPage]}</H4>
                <VSpacing base={4} />
                <FormSpacer>
                    <Radio name="brandingPreviewMode" value="1" onChange={onChange} checked={previewModeWithBranding}>
                        {trls[ControlContentPreviewMode.trls.withBranding]}
                    </Radio>
                    <div>
                        <VSpacing base={2} />
                        <Radio
                            name="brandingPreviewMode"
                            value="0"
                            onChange={onChange}
                            checked={!previewModeWithBranding}
                        >
                            {trls[ControlContentPreviewMode.trls.withoutBranding]}
                        </Radio>
                    </div>
                </FormSpacer>
            </div>
            <div className="employer-constructor-xs-hidden">
                <Button onClick={toggleEditMode} appearance={ButtonAppearance.Outlined}>
                    {trls[ControlContentPreviewMode.trls.goBack]}
                </Button>
            </div>
        </div>
    );
};

ControlContentPreviewMode.trls = {
    goBack: 'employer.constructor.controls.goBack',
    previewEmployerPage: 'employer.constructor.controls.previewEmployerPage',
    withBranding: 'employer.constructor.controls.withBranding',
    withoutBranding: 'employer.constructor.controls.withoutBranding',
};

ControlContentPreviewMode.propTypes = {
    trls: PropTypes.object,
    toggleEditMode: PropTypes.func,
};

export default translation(ControlContentPreviewMode);
