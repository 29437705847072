import Modal, { ModalHeader, ModalTitle, ModalContent } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import SubscribeModalChatBot from 'src/components/Employer/Sidebar/SubscribeModal/ChatBot';
import SubscribeModalEmail from 'src/components/Employer/Sidebar/SubscribeModal/Email';
import SubscribeModalSwitch, { ModalTypes } from 'src/components/Employer/Sidebar/SubscribeModal/Switch';

import styles from './subscribe-modal.less';

const TrlKeys = {
    [ModalTypes.Switch]: 'vacancySearchResults.saveSearch.switch.title',
    [ModalTypes.EmailSubscribe]: 'vacancySearchResults.saveSearch.withEmail.title',
    [ModalTypes.ChatBotSubscribe]: 'vacancySearchResults.saveSearch.withChatBot.title',
};

interface SubscribeModalProps {
    autosearchName: string;
    employerId: number;
    modalType: ModalTypes;
    showModal: boolean;
    switchModalType: (modalType: ModalTypes) => void;
    toggleShowModal: () => void;
}

const SubscribeModal: TranslatedComponent<SubscribeModalProps> = ({
    trls,
    autosearchName,
    employerId,
    modalType,
    showModal,
    switchModalType,
    toggleShowModal,
}) => (
    <Modal visible={showModal} onClose={toggleShowModal}>
        <ModalHeader outlined>
            <ModalTitle>{trls[TrlKeys[modalType]]}</ModalTitle>
        </ModalHeader>
        <ModalContent>
            <div className={styles.content}>
                {modalType === ModalTypes.Switch && <SubscribeModalSwitch switchModalType={switchModalType} />}
                {modalType === ModalTypes.EmailSubscribe && (
                    <SubscribeModalEmail
                        toggleShowModal={toggleShowModal}
                        employerId={employerId}
                        autosearchName={autosearchName}
                    />
                )}
                {modalType === ModalTypes.ChatBotSubscribe && <SubscribeModalChatBot />}
            </div>
        </ModalContent>
    </Modal>
);

export default translation(SubscribeModal);
