import React, { FC } from 'react';

import { useSelector } from 'lux/modules/useSelector';
import SsrGuard from 'src/components/SsrGuard';

import Content from 'src/components/VacancyView/WantSame/Content';

const WantSame: FC<{ keyPrefix: 'vacancyId' | 'employerId'; id: string }> = (props) => {
    const canShowWantMoreActionBar = useSelector((state) => state.canShowWantMoreActionBar);

    if (!canShowWantMoreActionBar) {
        return null;
    }

    return (
        <SsrGuard>
            <Content {...props} />
        </SsrGuard>
    );
};

export default WantSame;
