import PropTypes from 'prop-types';

import translation from 'src/components/translation';

const Snippet = ({ trls }) => (
    <div className="employer-constructor-edit-snippet">
        <div className="employer-snippet">{trls[Snippet.trls.needBuy]}</div>
    </div>
);

Snippet.propTypes = {
    trls: PropTypes.object.isRequired,
};

Snippet.trls = {
    needBuy: 'employer.constructor.snippet.needBuyRegion',
};

export default translation(Snippet);
