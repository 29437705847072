import { useState, PropsWithChildren, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Debug from 'HHC/Debug';
import { sendAnalyticsWatchForVacancies } from 'Modules/ApplicantAnalytics';
import useToggleState from 'lux/hooks/useToggleState';
import { setEmployerButton } from 'lux/models/employerButtons';
import { UserType } from 'lux/models/userType';
import { isServerError } from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';
import SubscribeModal from 'src/components/Employer/Sidebar/SubscribeModal';
import { ModalTypes } from 'src/components/Employer/Sidebar/SubscribeModal/Switch';
import autosearchSave, { STATUS } from 'src/components/EmployerConstructor/autosearchSave';
import defaultError from 'src/components/Notifications/DefaultError';
import {
    employerSidebarAutosearchSavedApplicant,
    employerSidebarAutosearchTooMany,
} from 'src/components/Notifications/EmployerSidebar';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';

import SubscribeContext from 'src/components/Employer/Subscribe/SubscribeContext';

const TrlKeys = {
    autosearchName: 'vacancySearchResults.saveSearchPreference.employerPage.companyVacancies',
};

const SubscribeProvider: TranslatedComponent<PropsWithChildren> = ({ trls, children }) => {
    const userType = useSelector(({ userType }) => userType);
    const employerInfo = useSelector(({ employerInfo }) => employerInfo);
    const { id: employerId, name: companyName } = employerInfo;
    const saveSearch = useSelector(({ employerButtons }) => employerButtons.saveSearch);
    const { count, emailRequired, userEmail } = saveSearch || {};
    const { addNotification } = useNotification();
    const dispatch = useDispatch();
    const chatBot = useSelector((state) => state.chatBot);
    const push = usePush();

    const [saveSearchSubmitDisabled, toggleSaveSearchSubmitDisabled] = useToggleState(false);
    const [showModal, toggleShowModal] = useToggleState(false);
    const [modalType, switchModalType] = useState(ModalTypes.Switch);

    const autosearchName = companyName ? `${trls[TrlKeys.autosearchName]}\u00a0${companyName}` : '';
    const isSubscribeAllowed = !!employerId && !!autosearchName && !!saveSearch;

    const handleSaveClick = useCallback(async () => {
        if (!isSubscribeAllowed) {
            Debug.log(
                'out error',
                new Error(`Missing information about company id=${String(employerId)} name=${String(companyName)}`)
            );
            return;
        }
        if (count) {
            push('/applicant/autosearch');
            return;
        }
        if (userType === UserType.Anonymous || !userEmail || emailRequired) {
            switchModalType(chatBot ? ModalTypes.Switch : ModalTypes.EmailSubscribe);
            toggleShowModal();
            return;
        }
        toggleSaveSearchSubmitDisabled();
        let response;
        try {
            response = await autosearchSave({ employerId, autosearchName });
        } catch (error) {
            if (isServerError<{ message?: string }>(error)) {
                addNotification(error.message === STATUS.CONFLICT ? employerSidebarAutosearchTooMany : defaultError);
            }
            toggleSaveSearchSubmitDisabled();
            return;
        }

        if (response?.state === STATUS.SUCCESS) {
            addNotification(employerSidebarAutosearchSavedApplicant);
            dispatch(
                setEmployerButton({
                    saveSearch: { count: (count || 0) + 1, emailRequired: Boolean(emailRequired), userEmail },
                })
            );
            sendAnalyticsWatchForVacancies();
        } else {
            addNotification(defaultError);
        }
        toggleSaveSearchSubmitDisabled();
    }, [
        addNotification,
        autosearchName,
        chatBot,
        companyName,
        count,
        dispatch,
        emailRequired,
        employerId,
        isSubscribeAllowed,
        push,
        toggleSaveSearchSubmitDisabled,
        toggleShowModal,
        userEmail,
        userType,
    ]);

    const contextValue = useMemo(
        () => ({
            handleSaveClick,
            saveSearchSubmitDisabled,
            count,
            isSubscribeAllowed,
        }),
        [count, handleSaveClick, isSubscribeAllowed, saveSearchSubmitDisabled]
    );
    return (
        <SubscribeContext.Provider value={contextValue}>
            {children}
            {isSubscribeAllowed && (
                <SubscribeModal
                    autosearchName={autosearchName}
                    employerId={employerId}
                    modalType={modalType}
                    showModal={showModal}
                    switchModalType={switchModalType}
                    toggleShowModal={toggleShowModal}
                />
            )}
        </SubscribeContext.Provider>
    );
};

export default translation(SubscribeProvider);
