import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './switch.less';

export enum ModalTypes {
    ChatBotSubscribe = 'chatBotSubscribe',
    EmailSubscribe = 'emailSubscribe',
    Switch = 'switch',
}

const TrlKeys = {
    text: 'vacancySearchResults.saveSearch.switch.text',
    emailButton: 'vacancySearchResults.saveSearch.switch.emailButton',
    chatBotButton: 'vacancySearchResults.saveSearch.switch.chatBotButton',
};

interface SubscribeModalSwitchProps {
    switchModalType: (modalType: ModalTypes) => void;
}

const SubscribeModalSwitch: TranslatedComponent<SubscribeModalSwitchProps> = ({ trls, switchModalType }) => (
    <>
        <Text>{trls[TrlKeys.text]}</Text>
        <VSpacing base={10} />
        <div className={styles.switchButtons}>
            <Button
                onClick={() => switchModalType(ModalTypes.EmailSubscribe)}
                kind={ButtonKind.Primary}
                appearance={ButtonAppearance.Outlined}
            >
                {trls[TrlKeys.emailButton]}
            </Button>
            <Button
                onClick={() => switchModalType(ModalTypes.ChatBotSubscribe)}
                kind={ButtonKind.Primary}
                appearance={ButtonAppearance.Outlined}
            >
                {trls[TrlKeys.chatBotButton]}
            </Button>
        </div>
    </>
);

export default translation(SubscribeModalSwitch);
