import { Fragment } from 'react';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'lux/modules/useSelector';
import FullPageLayout from 'src/app/layouts/FullPageLayout';
import ContainerForXSL from 'src/components/CodeInjector/ContainerForXSL';
import Portal from 'src/components/CodeInjector/Portal';
import ChameleonHeader from 'src/components/Employer/ChameleonHeader';
import { usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';
import Subscribe from 'src/components/Employer/Sidebar/Subscribe';
import SubscribeProvider from 'src/components/Employer/Subscribe/SubscribeProvider';
import VacanciesFilter from 'src/components/Employer/VacanciesFilter';
import EmployerReviewsBigWidget from 'src/components/EmployerReviews/WidgetBig';
import RowContent from 'src/components/RowContent';
import WantSame from 'src/components/VacancyView/WantSame';
import translation from 'src/components/translation';

import VacanciesLink from 'src/pages/EmployerView/components/VacanciesLink';

const TrlKeys = {
    subscribteText: 'employer.view.vacancy.autosearch.button',
};

const EmployerChameleon: TranslatedComponent = ({ trls }) => {
    const abortPageContent = useSelector((state) => state.abortPageContent);
    const employerId = useSelector((state) => state.employerInfo?.id);
    const disableChameleonHeader = useSelector((state) => state.disableChameleonHeader);
    const hideDefaultSubscribe = useSelector((state) => state.hideDefaultSubscribe);
    const { isEmployerTabsExp, isMobileView } = usePageTabsContext();

    const ContentWrapper = disableChameleonHeader ? Fragment : RowContent;

    return (
        <FullPageLayout>
            <ContentWrapper>
                {!disableChameleonHeader && <ChameleonHeader />}
                <ContainerForXSL place="legacy-page-layout-xsl" />
                <WantSame keyPrefix="employerId" id={String(employerId)} />
                <SubscribeProvider>
                    {!abortPageContent && (
                        <Portal place="vacancies-react-content">
                            {isEmployerTabsExp && isMobileView ? <VacanciesLink /> : <VacanciesFilter />}
                        </Portal>
                    )}
                    <ColumnsWrapper>
                        <Column l="16" m="12" s="8" xs="4">
                            {!hideDefaultSubscribe && (
                                <div className="employer-branded-savedsearch">
                                    <Subscribe subscribeButtonText={trls[TrlKeys.subscribteText]} />
                                </div>
                            )}
                            <EmployerReviewsBigWidget hasWrapper={false} />
                        </Column>
                    </ColumnsWrapper>
                </SubscribeProvider>
            </ContentWrapper>
        </FullPageLayout>
    );
};

export default translation(EmployerChameleon);
