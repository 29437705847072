import { Fragment, useMemo, useState, useCallback, ChangeEvent } from 'react';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import Checkbox from 'bloko/blocks/checkbox';
import Column from 'bloko/blocks/column';
import { FormItem, FormSpacer } from 'bloko/blocks/form';
import Gap from 'bloko/blocks/gap';
import BlokoLink from 'bloko/blocks/link';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Radio from 'bloko/blocks/radio';
import Text, { TextImportance } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { PriceWithFormatData } from 'lux/models/price/priceBranding/priceBranding.types';
import FormatMoney from 'src/components/FormatMoney';
import translation from 'src/components/translation';

export const ALL_RUSSIA = '3000233';
const RADIO_REGIONS_VALUE = '-1';

const TrlKeys = {
    title: 'price.branding.regionselect.title.new',
    region: 'price.branding.regionselect.region',
    cancel: 'price.branding.regionselect.cancel',
    button: 'price.branding.regionselect.button',
    caution: 'price.branding.regionselect.caution',
    cautionLink: 'price.branding.regionselect.cautionLink',
};

export interface HandleOnSelectType {
    (selectedRegions: PriceWithFormatData[], priceSum: number): void;
}

interface SelectRegionProps {
    visible: boolean;
    onClose: () => void;
    regionPrices: PriceWithFormatData[];
    onSelect: HandleOnSelectType;
    defaultRegions: string[];
    hasService: boolean;
}

const SelectRegion: TranslatedComponent<SelectRegionProps> = ({
    visible,
    onClose,
    regionPrices,
    onSelect,
    defaultRegions,
    hasService,
    trls,
}) => {
    const [selected, setSelected] = useState(defaultRegions);
    const [disabled, setDisabled] = useState<string[]>([]);

    const priceSum = useMemo(
        () =>
            selected.reduce((sum, currentRegionId) => {
                if (disabled.includes(currentRegionId)) {
                    return sum;
                }
                const region = regionPrices.find(({ priceRegionId }) => priceRegionId === currentRegionId);
                return sum + (region?.price || 0);
            }, 0),
        [disabled, regionPrices, selected]
    );

    const onSelectWrapper = useCallback(
        (disabledRegions: string[], selectedRegions: string[]) => {
            onSelect(
                regionPrices.filter(
                    (priceItem: PriceWithFormatData) =>
                        selectedRegions.includes(priceItem.priceRegionId) &&
                        !disabledRegions.includes(priceItem.priceRegionId)
                ),
                priceSum
            );
        },
        [onSelect, priceSum, regionPrices]
    );

    const onChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const regionId = event.target.value;
            const checked = event.target.checked;

            let selectedRegions = [...selected];
            let disabledRegions = [...disabled];

            if (regionId === ALL_RUSSIA) {
                selectedRegions = [ALL_RUSSIA];
                disabledRegions = [];
            } else if (regionId === RADIO_REGIONS_VALUE) {
                selectedRegions = [];
            } else if (checked) {
                selectedRegions = [...selectedRegions.filter((region) => region !== ALL_RUSSIA), regionId];
            } else {
                selectedRegions = selectedRegions.filter((region) => region !== regionId && region !== ALL_RUSSIA);
            }

            setDisabled(disabledRegions);
            setSelected(selectedRegions);
        },
        [disabled, selected]
    );

    const cancel = () => {
        setSelected(defaultRegions);
        onClose();
    };

    return (
        <>
            <Modal visible={visible} onClose={cancel}>
                <ModalHeader>
                    <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
                </ModalHeader>
                <ModalContent>
                    <Column xs="4" s="7" m="7" l="7" container data-qa="branding-region-popup">
                        {regionPrices.map((region) => {
                            const { priceRegionId, priceRegionTrl, price, currency } = region;
                            const checked = selected.includes(priceRegionId);
                            const disabledInput = disabled.includes(priceRegionId);
                            const inputContent = (
                                <>
                                    {priceRegionTrl}{' '}
                                    <Text Element="span" importance={TextImportance.Tertiary}>
                                        <FormatMoney currency={currency}>{disabledInput ? 0 : price}</FormatMoney>
                                    </Text>
                                </>
                            );
                            if (priceRegionId === ALL_RUSSIA) {
                                return (
                                    <Fragment key={priceRegionId}>
                                        <FormItem>
                                            <Radio
                                                name="region"
                                                value={String(priceRegionId)}
                                                onChange={onChange}
                                                checked={checked}
                                            >
                                                {inputContent}
                                            </Radio>
                                        </FormItem>
                                        <FormItem>
                                            <Radio
                                                name="region"
                                                value={String(RADIO_REGIONS_VALUE)}
                                                onChange={onChange}
                                                checked={!checked}
                                            >
                                                {trls[TrlKeys.region]}
                                            </Radio>
                                        </FormItem>
                                    </Fragment>
                                );
                            }
                            return (
                                <div className="like-checkable" key={priceRegionId}>
                                    <FormItem>
                                        <Checkbox
                                            name="region"
                                            value={String(priceRegionId)}
                                            onChange={onChange}
                                            checked={checked}
                                            disabled={disabledInput}
                                            data-qa={`branding-region-popup_item_${String(priceRegionId)}`}
                                        >
                                            {inputContent}
                                        </Checkbox>
                                    </FormItem>
                                </div>
                            );
                        })}
                    </Column>
                </ModalContent>
                <ModalFooter>
                    {hasService && (
                        <Gap bottom>
                            <span>
                                {formatToReactComponent(trls[TrlKeys.caution], {
                                    '{0}': (
                                        <BlokoLink
                                            disableVisited
                                            Element={Link}
                                            to={`/employer/account/services`}
                                            target="_blank"
                                            data-qa="branding-region-popup-caution-link"
                                        >
                                            {trls[TrlKeys.cautionLink]}
                                        </BlokoLink>
                                    ),
                                })}
                            </span>
                        </Gap>
                    )}
                    <Button onClick={cancel}>{trls[TrlKeys.cancel]}</Button>
                    <FormSpacer>
                        <Button
                            kind={ButtonKind.Primary}
                            disabled={!selected.length}
                            data-qa={'branding-region-popup-apply'}
                            onClick={useCallback(() => {
                                onSelectWrapper(disabled, selected);
                                onClose();
                            }, [disabled, onClose, onSelectWrapper, selected])}
                        >
                            {formatToReactComponent(trls[TrlKeys.button], {
                                '{0}': <FormatMoney currency={regionPrices[0]?.currency}>{priceSum}</FormatMoney>,
                            })}
                        </Button>
                    </FormSpacer>
                </ModalFooter>
            </Modal>
        </>
    );
};
export default translation(SelectRegion);
