import { Button } from '@hh.ru/magritte-ui';
import { ArrowRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { WantWorkHereLink } from 'src/components/Employer/Sidebar/WantWorkHere';
import translation from 'src/components/translation';

const TrlKeys = {
    employerViewNeedWork: 'employer.page.wantWork',
};

const WantWorkMobile: TranslatedComponent = ({ trls }) => (
    <Button
        stretched
        Element={WantWorkHereLink}
        size="medium"
        style="accent"
        mode="secondary"
        icon={<ArrowRightOutlinedSize24 initial="accent" />}
    >
        {trls[TrlKeys.employerViewNeedWork]}
    </Button>
);

export default translation(WantWorkMobile);
