export const SHOW_CONNECT_REVIEWS_MODAL_EVENT = 'HH-ERF-SHOW_CONNECT_REVIEWS_MODAL';

type EmployerConstructor = { pictureId: number | null; sidebarColors: Array<object> };

export const openConnectReviewsModal = (
    isRussia: boolean,
    employerConstructor: EmployerConstructor,
    validWidgets: Array<object>
): void => {
    const isCustomProfile =
        hasPicture(employerConstructor) || isSidebarHasChangedColors(employerConstructor) || hasWidgets(validWidgets);

    if (isRussia && isCustomProfile) {
        window.dispatchEvent(new CustomEvent(SHOW_CONNECT_REVIEWS_MODAL_EVENT));
    }
};

function hasPicture(employerConstructor: EmployerConstructor) {
    return employerConstructor.pictureId !== null;
}
function isSidebarHasChangedColors(employerConstructor: EmployerConstructor) {
    return Object.keys(employerConstructor.sidebarColors).length > 0;
}
function hasWidgets(validWidgets: Array<object>) {
    return validWidgets.length > 0;
}
