import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import VSpacing from 'bloko/blocks/vSpacing';

import { useIsRussia } from 'lux/hooks/useCountries';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import HeaderTitle from 'src/components/Employer/HeaderTitle';
import EmployerReviewsWidgetSmall from 'src/components/EmployerReviews/WidgetSmall';

const HeaderBase = ({ imagePath, children, hhcdnHost, employerAccepted }) => {
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();
    return (
        <div
            style={imagePath && { backgroundImage: `url(${hhcdnHost}${imagePath})` }}
            className={classnames('employer-constructor-header', {
                'employer-constructor-header_without-image': !imagePath,
            })}
        >
            <ColumnsWrapper>
                <Column xs="0" s="0" m="3" l="3" />
                <Column xs="4" s="8" m="9" l="13">
                    <div className="employer-constructor-header__title">
                        <div className="employer-constructor-header__title-wrapper">
                            <HeaderTitle inverted />
                            {employerAccepted && isRussia && !isZP && (
                                <>
                                    <EmployerReviewsWidgetSmall white />
                                    <VSpacing base={6} xs={3} />
                                </>
                            )}
                        </div>
                    </div>
                </Column>
            </ColumnsWrapper>
            {children}
        </div>
    );
};

HeaderBase.propTypes = {
    imagePath: PropTypes.string,
    children: PropTypes.node,
    hhcdnHost: PropTypes.string,
    employerAccepted: PropTypes.bool,
};

export default connect(({ employerConstructor, employerInfo, config }) => ({
    imagePath: employerConstructor.images.find((image) => image.pictureId === employerConstructor.pictureId)?.path,
    hhcdnHost: config.hhcdnHost,
    employerAccepted: employerInfo.accepted,
}))(HeaderBase);
