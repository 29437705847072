import PropTypes from 'prop-types';

import Button, { ButtonIconPosition } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import { TrashScaleSmall, PenSquareScaleSmall, CameraScaleSmall } from 'bloko/blocks/icon';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';

import { Status } from 'lux/models/employerConstructor';
import translation from 'src/components/translation';

const HeaderEditControls = ({
    trls,
    getInputProps,
    resizeStatus,
    allowedMimeTypes,
    onLoad,
    enhancedToggleCropPopupVisible,
    removeHeaderImage,
}) => (
    <div className="employer-constructor-header__controls">
        <label>
            <Button
                Element="span"
                icon={<CameraScaleSmall />}
                loading={
                    resizeStatus === Status.Fetching && (
                        <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />
                    )
                }
                iconPosition={ButtonIconPosition.Left}
            >
                {trls[HeaderEditControls.trls.uploadAnother]}
                <input
                    {...getInputProps()}
                    type="file"
                    className="g-hidden"
                    accept={allowedMimeTypes}
                    onChange={onLoad}
                    data-qa="constructor-head__upload-image-input"
                />
            </Button>
        </label>
        <div className="employer-constructor-header__button-wrapper">
            <HoverTip
                host={!process.env.LUX_SERVER ? document.body : null}
                render={() => trls[HeaderEditControls.trls.editTooltip]}
            >
                <Button
                    onClick={enhancedToggleCropPopupVisible}
                    icon={<PenSquareScaleSmall />}
                    data-qa="constructor-head__edit-image-button"
                />
            </HoverTip>
        </div>
        <div className="employer-constructor-header__button-wrapper">
            <Button
                onClick={removeHeaderImage}
                icon={<TrashScaleSmall />}
                data-qa="constructor-head__remove-image-button"
            />
        </div>
    </div>
);

HeaderEditControls.propTypes = {
    trls: PropTypes.object,
    allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
    resizeStatus: PropTypes.string,
    onLoad: PropTypes.func,
    getInputProps: PropTypes.func,
    enhancedToggleCropPopupVisible: PropTypes.func,
    removeHeaderImage: PropTypes.func,
};

HeaderEditControls.trls = {
    uploadAnother: 'employer.constructor.header.uploadAnother',
    editTooltip: 'employer.constructor.header.edit.tooltip',
};

export default translation(HeaderEditControls);
